import { LOADING_EXTENSION, GET_EXTENSION_DETAILS, UPDATE_EXTENSION_SETTING } from "../../actions/type";

const INITIAL_STATE = {
    details: [{}],
    ext_details: [{}],
    is_loading: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOADING_EXTENSION:
            return { ...state, is_loading: true };
        case GET_EXTENSION_DETAILS:
            return { ...state, details: action.payload, is_loading: false };
        case UPDATE_EXTENSION_SETTING:
            return { ...state, ext_details: action.payload, is_loading: false };
        default:
            return state;
    }
};
