import React, { useState, useEffect } from "react";
import Table from "../../components/Table/Table";
import AdminLayout from "../../layout/adminLayout";
import editIcon from "../../../assets/images/quix-edit-video.png";
import TrashIcon from "../../assets/images/trash-icon.png";
import suspendIcon from "../../assets/images/slash-icon.png";
import dateFormat from "dateformat";
import { connect, useDispatch } from "react-redux";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { checkServerURL } from "../../../helper/helper";
import { API_URL, localStoragePrefix } from "../../../utils/config";
import UserProfileCard from "../../components/Cards/UserProfileCard";
import dummyUserIcon from "../../assets/images/dumy-user.jpg";
import EditActionModal from "../../components/Modal/EditActionModal";
import actionIcon from "../../assets/images/action-icon.png";
import {
  errorMessageModal,
  successMessageModal,
} from "../../components/Modal/MessagesModal";
import {
  addTagAdmin,
  deleteTagAdmin,
  editTagAdmin,
  getAllTagsListAdmin,
  getAllTagsListAdminUsers,
  SearchTagsAdmin,
} from "../../../redux/actions/admin/tags";
import Filters from "../../components/Filters/Filters";
import FilterContent from "../../components/Filters/FilterContent";
import CreateTags from "./components/CreateTags";
import EditTags from "./components/EditTags";
import {
  adminTagCSV,
  confirmationModalMessage,
  tagStatusHandler,
  userTagCSV,
} from "./helper";

const TagsList = ({
  tagsList,
  isLoading,
  notFound,
  isLoadingUsersTags,
  usersTagsList,
  notFoundUsersTags,
}) => {
  const [tagCardData, setTagCardData] = useState(null);
  const [showSuspendActiveModal, setShowSuspendActiveModal] = useState(false);
  const [getPreDefinedTagData, setPreDefinedTagData] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [isAllSelected, setIsAllSelected] = useState([]);
  const [selectedItemsIDs, setSelectedItemsIDs] = useState([]);
  const [isUserTagSelected, setIsUserTagSelected] = useState(false);
  const [selectedUserTagsItemsIDs, setSelectedUserItemsIDs] = useState([]);
  const [showTableAction, setShowTableAction] = useState(false);
  const [showTableActionUser, setShowTableActionUser] = useState(false);
  const dispatch = useDispatch();

  const userID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );

  useEffect(() => {
    if (userID) {
      dispatch(getAllTagsListAdmin(Number(userID)));
      dispatch(getAllTagsListAdminUsers(Number(userID)));
    }
  }, [userID]);

  const [activeTab, setActiveTab] = useState("admin-tags");

  // get all admin tags
  const tagsData = tagsList?.data ? tagsList?.data : [];
  const userTagsData = usersTagsList?.data ? usersTagsList?.data : [];

  useEffect(() => {
    let firstRowAdmin = tagsData ? tagsData?.[0] : {};
    let firstRowUser = userTagsData ? userTagsData?.[0] : {};
    if (activeTab === "admin-tags") {
      setTagCardData(firstRowAdmin);
    } else {
      setTagCardData(firstRowUser);
    }
  }, [activeTab]);

  const columns = [
    {
      Header: (
        <input
          className="form-check-input check-items"
          type="checkbox"
          onChange={(e) => handleMultiSelectItems(e, "admin")}
          checked={isAllSelected ? true : false}
        />
      ),
      accessor: "check",
      disableSortBy: true,
      Cell: (row) => (
        <>
          <input
            className="form-check-input check-items"
            type="checkbox"
            checked={
              selectedItemsIDs?.includes(row?.row?.original?.id) ? true : false
            }
            onChange={(e) =>
              handleChangeSelectedItems(e, row?.row?.original?.id, "admin")
            }
          />
        </>
      ),
    },
    { Header: "#", accessor: (row, i) => i + 1 },
    {
      Header: "User name",
      Cell: (row) => (
        <UserProfileCard
          src={
            !row?.row?.original?.userDetails?.picture ||
              row?.row?.original?.userDetails?.picture === "null" ||
              row?.row?.original?.userDetails?.picture === null
              ? dummyUserIcon
              : checkServerURL(
                API_URL,
                row?.row?.original?.userDetails?.picture
              )
          }
          name={row?.row?.original?.userDetails?.name}
        />
      ),
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value }) => (value ? value.slice(0, 40) : ""),
    },
    {
      Header: "Create At",
      Cell: (row) => {
        return (
          <span>
            {dateFormat(row?.row?.original?.create_at, "dd/mm/yyyy hh:MM TT")}
          </span>
        );
      },
    },
    {
      Header: "Status",
      Cell: (row) => (
        <>
          <div
            className={`btn ${tagStatusHandler(row?.row?.original?.status).class
              }`}
          >
            {tagStatusHandler(row?.row?.original?.status).status}
          </div>
        </>
      ),
    },
    {
      Header: "Action",
      Cell: (row) => (
        <div className="action-cell">
          <div className="edit-action-cell">
            <img className="action-img" src={actionIcon} />
            <EditActionModal>
              {row?.row?.original.status === "1" ? (
                <div
                  className="column-action gap-2"
                  onClick={() => {
                    modalAction("SUSPEND", "admin");
                    setItemId(row?.row?.original?.id);
                  }}
                >
                  <img src={suspendIcon} />
                  <span>Suspend Tag</span>
                </div>
              ) : (
                <div
                  className="column-action gap-2"
                  onClick={() => {
                    modalAction("ACTIVATE", "admin");
                    setShowSuspendActiveModal(!showSuspendActiveModal);
                    setItemId(row?.row?.original?.id);
                  }}
                >
                  <img src={suspendIcon} />
                  <span>Activate Tag</span>
                </div>
              )}
              <div
                className="column-action gap-2"
                disabled={loading}
                onClick={() => {
                  setTagCardData(row?.row?.original);
                }}
              >
                <img src={editIcon} />
                <span>Edit Tag</span>
              </div>
              <div
                className="column-action delete-action gap-2"
                disabled={loading}
                onClick={() => {
                  modalAction("DELETE", "admin");
                  setItemId(row?.row?.original?.id);
                  setSelectedItemsIDs([]);
                  setSelectedUserItemsIDs([]);
                }}
              >
                <img src={TrashIcon} />
                <span>Delete Tag</span>
              </div>
            </EditActionModal>
          </div>
        </div>
      ),
    },
  ];

  const UserColumns = [
    {
      Header: (
        <input
          className="form-check-input check-items"
          type="checkbox"
          onChange={(e) => handleMultiSelectItems(e, "user")}
          checked={isUserTagSelected ? true : false}
        />
      ),
      accessor: "check",
      disableSortBy: true,
      Cell: (row) => (
        <>
          <input
            className="form-check-input check-items"
            type="checkbox"
            checked={
              selectedUserTagsItemsIDs?.includes(row?.row?.original?.id)
                ? true
                : false
            }
            onChange={(e) =>
              handleChangeSelectedItems(e, row?.row?.original?.id, "user")
            }
          />
        </>
      ),
    },
    { Header: "#", accessor: (row, i) => i + 1 },
    {
      Header: "User name",
      Cell: (row) => (
        <UserProfileCard
          src={
            !row?.row?.original?.userDetails?.picture ||
              row?.row?.original?.userDetails?.picture === "null" ||
              row?.row?.original?.userDetails?.picture === null
              ? dummyUserIcon
              : checkServerURL(
                API_URL,
                row?.row?.original?.userDetails?.picture
              )
          }
          name={row?.row?.original?.userDetails?.name}
        />
      ),
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value }) => (value ? value.slice(0, 40) : ""),
    },
    {
      Header: "Create At",
      Cell: (row) => {
        return (
          <span>
            {dateFormat(row?.row?.original?.create_at, "dd/mm/yyyy hh:MM TT")}
          </span>
        );
      },
    },
    {
      Header: "Status",
      Cell: (row) => (
        <>
          <div
            className={`btn ${tagStatusHandler(row?.row?.original?.status).class
              }`}
          >
            {tagStatusHandler(row?.row?.original?.status).status}
          </div>
        </>
      ),
    },
    {
      Header: "Action",
      Cell: (row) => (
        <div className="action-cell">
          <div className="edit-action-cell">
            <img className="action-img" src={actionIcon} />
            <EditActionModal>
              <div
                className="column-action gap-2"
                disabled={loading}
                onClick={() => {
                  modalAction("MARK_ADMIN", "user");
                  setPreDefinedTagData(row?.row?.original);
                  setItemId(row?.row?.original?.id);
                }}
              >
                <span>Mark as Admin Tag</span>
              </div>
              <div
                className="column-action gap-2"
                disabled={loading}
                onClick={() => {
                  setTagCardData(row?.row?.original);
                }}
              >
                <img src={editIcon} />
                <span>Edit Tag</span>
              </div>
              <div
                className="column-action delete-action gap-2"
                disabled={loading}
                onClick={() => {
                  modalAction("DELETE", "user");
                  setItemId(row?.row?.original?.id);
                  setSelectedItemsIDs([]);
                  setSelectedUserItemsIDs([]);
                }}
              >
                <img src={TrashIcon} />
                <span>Delete Tag</span>
              </div>
            </EditActionModal>
          </div>
        </div>
      ),
    },
  ];

  const [loading, setLoading] = useState(false);
  const [showItemData, setShowItemData] = useState([]);
  const visibleItems = showItemData && showItemData;
  const [showItemUserTagData, setShowItemUserTagData] = useState([]);
  const visibleUserTagItems = showItemUserTagData && showItemUserTagData;
  // handle multi select screenshot
  const handleMultiSelectItems = (e, type) => {
    let checked = e.target.checked;
    if (type === "admin") {
      if (checked) {
        setSelectedUserItemsIDs([]);
        let newSelectedIDs = visibleItems.map((user) => user.id);
        setSelectedItemsIDs((prevIDs) => [
          ...new Set([...prevIDs, ...newSelectedIDs]),
        ]);
      } else {
        setSelectedItemsIDs([]);
      }
    } else if (type === "user") {
      if (checked) {
        setSelectedItemsIDs([]);
        let newSelectedIDs = visibleUserTagItems.map((user) => user.id);
        setSelectedUserItemsIDs((prevIDs) => [
          ...new Set([...prevIDs, ...newSelectedIDs]),
        ]);
      } else {
        setSelectedUserItemsIDs([]);
      }
    }
  };

  // handle single select screenshot
  const handleChangeSelectedItems = (e, id, type) => {
    const checked = e.target.checked;
    if (type === "admin") {
      setSelectedUserItemsIDs([]);
      setSelectedItemsIDs((prevSelectedItemsIDs) => {
        if (checked) {
          if (!prevSelectedItemsIDs.includes(id)) {
            return [...prevSelectedItemsIDs, id];
          }
        } else {
          return prevSelectedItemsIDs.filter((item) => item !== id);
        }
        return prevSelectedItemsIDs;
      });
    } else if (type === "user") {
      setSelectedItemsIDs([]);
      setSelectedUserItemsIDs((prevSelectedItemsIDs) => {
        if (checked) {
          if (!prevSelectedItemsIDs.includes(id)) {
            return [...prevSelectedItemsIDs, id];
          }
        } else {
          return prevSelectedItemsIDs.filter((item) => item !== id);
        }
        return prevSelectedItemsIDs;
      });
    }
  };

  useEffect(() => {
    if (selectedItemsIDs) {
      setIsAllSelected(
        visibleItems.length > 0 &&
        visibleItems.every((user) => selectedItemsIDs.includes(user.id))
      );
      if (selectedItemsIDs?.length > 0) {
        setShowTableAction(true);
      } else {
        setShowTableAction(false);
      }
    }
    if (selectedUserTagsItemsIDs) {
      setIsUserTagSelected(
        visibleUserTagItems.length > 0 &&
        visibleUserTagItems.every((user) =>
          selectedUserTagsItemsIDs.includes(user.id)
        )
      );
      if (selectedUserTagsItemsIDs?.length > 0) {
        setShowTableActionUser(true);
      } else {
        setShowTableActionUser(false);
      }
    }
  }, [
    selectedItemsIDs,
    selectedUserTagsItemsIDs,
    visibleItems,
    visibleUserTagItems,
  ]);

  // handle search all tags
  const handleSearchTags = (data) => {
    if (data) {
      const payload = {
        text: data,
        admin_id: Number(userID),
        query: "admin",
      };
      dispatch(SearchTagsAdmin(payload));
    }
  };

  // handle reset all users and suspend users
  const handleReset = () => {
    dispatch(getAllTagsListAdmin(Number(userID)));
  };

  // handle search all tags
  const handleSearchUsersTags = (data) => {
    if (data) {
      const payload = {
        text: data,
        admin_id: Number(userID),
        query: "user",
      };
      dispatch(SearchTagsAdmin(payload));
    }
  };

  // handle reset all users and suspend users
  const handleResetUsersTags = () => {
    dispatch(getAllTagsListAdminUsers(Number(userID)));
  };
  // handle search all users csv data
  let tagsDataCSV = adminTagCSV(tagsData);
  let usersTagsDataCSV = userTagCSV(userTagsData);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    action: "",
    title: "",
    message: "",
    type: "",
    icon: null,
  });
  const modalAction = (action, type) => {
    const modalContent = confirmationModalMessage({
      action: action,
      type: type,
    });
    if (modalContent) {
      setShowConfirmModal(true);
      setModalContent(modalContent);
    }
  };

  const callBackAction = (res, type, tab) => {
    if (res) {
      switch (type) {
        case "SUSPEND":
          return handleSuspendAction({ id: [itemId], status: "0" });
        case "MULTI_SUSPEND":
          return handleSuspendAction({
            id: tab === "admin" ? selectedItemsIDs : selectedUserTagsItemsIDs,
            status: "0",
          });
        case "ACTIVATE":
          return handleSuspendAction({ id: [itemId], status: "1" });
        case "DELETE":
          return handleDeleteAction({ id: [itemId] });
        case "MULTI_DELETE":
          return handleDeleteAction({
            id: tab === "admin" ? selectedItemsIDs : selectedUserTagsItemsIDs,
          });
        case "MARK_ADMIN":
          return handleMoveToPreDefinedTag({
            id: [itemId],
            user_id: Number(userID),
            admin_id: Number(userID),
          });
        default:
          return null;
      }
    }
  };

  const handleSuspendAction = (res) => {
    if (res) {
      setLoading(true);
      setShowConfirmModal(false);
      setSelectedItemsIDs([]);
      setSelectedUserItemsIDs([]);
      const payload = {
        admin_id: Number(userID),
        id: res?.id?.join(","),
        status: res?.status,
      };
      dispatch(
        editTagAdmin(payload, (type, res) => {
          if (type === "success") {
            if (res?.response?.[0]?.status === "1") {
              successMessageModal(
                "Tag Reactivated.",
                "Successful tag reactivated."
              );
            } else {
              successMessageModal(
                "Tag suspend successful.",
                "Successful suspend a tag."
              );
            }
            setLoading(false);
            handleReset();
            handleResetUsersTags();
          } else if (type === "failed") {
            errorMessageModal("Error Message", res?.message);
            setLoading(false);
          }
        })
      );
    }
  };

  const handleMoveToPreDefinedTag = (res) => {
    if (res) {
      setShowConfirmModal(false);
      const payload = {
        user_id: res?.user_id,
        admin_id: res?.admin_id,
        id: res?.id?.join(","),
      };
      dispatch(
        editTagAdmin(payload, (type, res) => {
          if (type === "success") {
            successMessageModal(
              "Predefined Tag Successful",
              "Successful predefined a tag."
            );
            handleReset();
            handleResetUsersTags();
          } else if (type === "failed") {
            errorMessageModal("Error Message", res?.message);
          }
        })
      );
    }
  };

  const handleDeleteAction = (res) => {
    const payload = {
      admin_id: Number(userID),
      id: res?.id,
      status: res?.status,
    };
    setShowConfirmModal(false);
    setSelectedUserItemsIDs([]);
    setSelectedItemsIDs([]);
    dispatch(
      deleteTagAdmin(payload, (type, res) => {
        if (type === "success") {
          setLoading(false);
          successMessageModal(
            "Tag Successfully Deleted.",
            "Tag has been permanently deleted."
          );
          dispatch(getAllTagsListAdmin(Number(userID)));
          dispatch(getAllTagsListAdminUsers(Number(userID)));
        } else if (type === "fail") {
          setLoading(false);
          errorMessageModal("Error Message", res?.message);
        }
      })
    );
  };

  return (
    <AdminLayout pageTitle={"tags"}>
      <div className="quix-screenshot-content">
        <div className="table-management-section">
          <div className="management-header mb-5">
            <h4>Tags Management</h4>
            <p>You can manage tags, can be create new tag and edit the tag.</p>
          </div>
          <Filters
            filterList={[
              {
                id: "admin-tags",
                className: "admin-tags active",
                title: "Admin Tags",
                onClick: () => setActiveTab("admin-tags"),
              },
              {
                id: "users-tags",
                className: "users-tags",
                title: "Users Tags",
                onClick: () => setActiveTab("users-tags"),
              },
            ]}
          />
        </div>
        <div className="row">
          <div className="col-md-8 mb-3">
            <FilterContent
              filterContent={[
                {
                  id: "admin-tags",
                  className: "show active admin-tags-tab",
                  childrenData: (
                    <Table
                      tableData={tagsData}
                      tableColumns={columns}
                      setShowItems={(e) => setShowItemData(e)}
                      searchBar={true}
                      isLoading={isLoading}
                      notFound={notFound}
                      searchCallBack={(res) => handleSearchTags(res)}
                      resetCallBack={() => handleReset()}
                      csvData={tagsDataCSV}
                      tableType={"tag"}
                      showAction={showTableAction}
                      actionBtn={
                        <>
                          <button
                            className="btn delete-btn"
                            onClick={() => {
                              modalAction("MULTI_DELETE", "admin");
                            }}
                          >
                            <img src={TrashIcon} />
                            Delete
                          </button>
                          <button
                            className="btn"
                            onClick={() =>
                              modalAction("MULTI_SUSPEND", "admin")
                            }
                          >
                            <img src={suspendIcon} />
                            Suspend
                          </button>
                        </>
                      }
                    />
                  ),
                },
                {
                  id: "users-tags",
                  className: "users-tags-tab",
                  childrenData: (
                    <Table
                      tableData={userTagsData}
                      tableColumns={UserColumns}
                      setShowItems={(e) => setShowItemUserTagData(e)}
                      searchBar={true}
                      isLoading={isLoadingUsersTags}
                      notFound={notFoundUsersTags}
                      searchCallBack={(res) => handleSearchUsersTags(res)}
                      resetCallBack={() => handleResetUsersTags()}
                      csvData={usersTagsDataCSV}
                      tableType={"tag"}
                      showAction={showTableActionUser}
                      actionBtn={
                        <>
                          <button
                            className="btn delete-btn"
                            onClick={() => {
                              modalAction("MULTI_DELETE", "user");
                            }}
                          >
                            <img src={TrashIcon} />
                            Delete
                          </button>
                        </>
                      }
                    />
                  ),
                },
              ]}
            />
          </div>
          <div className="col-md-4">
            {activeTab === "admin-tags" && <CreateTags />}
            <EditTags cardData={tagCardData} />
          </div>
        </div>
        {showConfirmModal && (
          <ConfirmationModal
            isShowModal={showConfirmModal}
            closeModal={setShowConfirmModal}
            callBack={(res) =>
              callBackAction(res, modalContent?.type, modalContent?.tab)
            }
            isLoading={false}
            isDisabled={false}
            icon={modalContent?.icon}
            btnText={"Continue"}
            messageTitle={modalContent?.title}
            description={modalContent?.message}
          />
        )}
      </div>
    </AdminLayout>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state?.admin_tags?.is_loading,
  tagsList: state?.admin_tags?.list,
  notFound: state?.admin_tags?.not_found,
  isLoadingUsersTags: state?.admin_tags?.is_loading_users_tags,
  usersTagsList: state?.admin_tags?.users_tags_list,
  notFoundUsersTags: state?.admin_tags?.not_found_users_tags,
});

export default connect(mapStateToProps, { getAllTagsListAdmin, addTagAdmin })(
  TagsList
);
