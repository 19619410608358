import React from "react";
import logoImg from "../../assets/images/home/logo.png";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import TopBar from "../../components/TopBar/TopBar";

const AboutUs = () => {
  return (
    <div>
      <TopBar />
      <div className="header_section">
        <div className="container top_container">
          <header className="ScreenGenius-header">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                  <img className="sitetitle" src={logoImg} height="80" alt="CoolBrand" />
                </Link>
              </div>
            </nav>
          </header>
          <div className="container-fluid">
            <div className="row">
              <div className="star_emoji">
                <span className="thankEmoji">
                  <span className="about-us">
                    <span className="emoji_content"></span>
                    <h5>About Us</h5>
                    <p>
                      <b>VividMinds Technologies Private Limited </b>
                      <is>
                        a Hyderabad based Startup firm founded in the year 2019
                        under Startup India program. Vividminds Technologies
                        Private Limited is into IT product development and{" "}
                      </is>
                      <b>"Vizam"</b>
                      <is>
                        the flagship product of our organization. Vizam
                        is an ultimate solution for all things related to
                        screenshots and screen recordings. Our innovative tool
                        empowers you to effortlessly capture your screen, edit,
                        annotate, and share with ease. With Vizam, you
                        gain access to a user-friendly dashboard tailored to
                        your needs, allowing you to conveniently view, edit, and
                        share your media with the world. Join us and experience
                        the simplicity and efficiency of Vizam today!
                      </is>
                    </p>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AboutUs;
