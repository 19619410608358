import React from "react";

const FilterContent = ({ filterContent }) => {
    return (
        <div className="tab-content" id="pills-tabContent">
            {filterContent && filterContent?.map((content, index) => {
                return (
                    <div
                        key={index}
                        className={`tab-pane fade ${content?.className}`}
                        id={`${content?.id}-pill`}
                        role="tabpanel"
                        aria-labelledby={`pills-${content?.id}-tab`}
                        tabIndex="0"
                    >
                        {content.childrenData}
                    </div>
                )
            })}
        </div>
    );
};

export default FilterContent;
