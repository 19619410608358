import React from "react";
import {
    getTwitterRequest,
    getTwitterLogin,
} from "../../../../redux/actions/social-share";

const TwitterLogin = ({ redirect_Url, callback, children }) => {
    const handleCallTwitter = async () => {
        if (redirect_Url) {
            getTwitterRequest(redirect_Url, (type, res) => {
                if (type === "success") {
                    const authToken = res?.data?.oauth_token;
                    const twitterAuthUrl = `https://api.twitter.com/oauth/authorize?oauth_token=${authToken}`;
                    const width = 600;
                    const height = 600;
                    const left = window.screen.width / 2 - width / 2;
                    const top = window.screen.height / 2 - height / 2;
                    const popup = window.open(
                        twitterAuthUrl,
                        "Twitter",
                        `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,resizable=yes`
                    );
                    async function handleAuthResponse(event) {
                        const authToken = event.data.oauth_token;
                        const authVerifier = event.data.oauth_verifier;
                        if (authToken && authVerifier) {
                            const payload = {
                                oauthToken: authToken,
                                oauthVerifier: authVerifier,
                            };
                            getTwitterLogin(payload, (type, res) => {
                                if (type === "success") {
                                    callback("success", res);
                                    window.removeEventListener("message", handleAuthResponse);
                                    popup.close();
                                } else if (type === "failed") {
                                    callback("failed", res);
                                    window.removeEventListener("message", handleAuthResponse);
                                    popup.close();
                                }
                            });
                        }
                    }
                    window.addEventListener("message", handleAuthResponse);
                } else {
                    callback("failed", res);
                }
            });
        }
    };

    return <div onClick={() => handleCallTwitter()}>{children}</div>;
};

TwitterLogin.defaultProps = {
    redirect_Url: "",
    callback: function () { },
};

export default TwitterLogin;