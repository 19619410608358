import axios from "axios";
import { API_URL } from "../../../utils/config";
import {
  ADMIN_ADD_PRICING_PLAN,
  GET_ALL_ADMIN_PRICING_PLAN,
  LOADING_ADMIN_PRICING_PLAN,
  UPDATE_ADMIN_PRICING_PLAN,
} from "../type";

export const getAllPricingPlansListAdmin = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING_ADMIN_PRICING_PLAN,
      });

      let data = JSON.stringify({
        admin_id: id,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        `${API_URL}/admin/pricing-plan/list`,
        data,
        config
      );
      if (res?.data?.status === true) {
        dispatch({
          type: GET_ALL_ADMIN_PRICING_PLAN,
          payload: res?.data,
        });
      }
    } catch (error) {
      console.log(error, "--error--");
    }
  };
};

export const addPricingPlanAdmin = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = new FormData();
      data.append("admin_id", payload?.admin_id);
      data.append("price", payload?.price);
      data.append("price_string", payload?.price_string);
      data.append("duration", payload?.duration);
      data.append("picture", payload?.picture);
      data.append("description", payload?.description);
      data.append("features", payload?.features);
      data.append("status", payload?.status);

      let config = {
        maxBodyLength: Infinity,
      };

      const res = await axios.post(
        `${API_URL}/admin/pricing-plan/add`,
        data,
        config
      );

      if (res?.data?.status === true) {
        dispatch({
          type: ADMIN_ADD_PRICING_PLAN,
          payload: res?.data,
        });
        callBack("success", res?.data);
      }
    } catch (error) {
      const message = error?.response?.data;
      callBack("fail", message);
      console.log(error, "---error---");
    }
  };
};

export const updatePricingPlanAdmin = (payload, callBack) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING_ADMIN_PRICING_PLAN,
      });
      let data = new FormData();
      data.append("admin_id", payload?.admin_id);
      data.append("price", payload?.price);
      data.append("status", payload?.status);
      data.append("price_string", payload?.price_string);
      data.append("duration", payload?.duration);
      data.append("picture", payload?.picture);
      data.append("description", payload?.description);
      data.append("features", payload?.features);

      let config = {
        maxBodyLength: Infinity,
      };

      const res = await axios.patch(
        `${API_URL}/admin/pricing-plan/update/${payload?.id}`,
        data,
        config
      );
      if (res?.data?.status === true) {
        dispatch({
          type: UPDATE_ADMIN_PRICING_PLAN,
          payload: res?.data,
        });
        callBack("success", res?.data);
      }
    } catch (error) {
      let message = error?.response?.data;
      callBack("fail", message);
      console.log(error, "--error--");
    }
  };
};

export const deletePricingPlanAdmin = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        admin_id: payload?.admin_id,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const res = await axios.delete(
        `${API_URL}/admin/pricing-plan/delete/${payload?.id}`,
        config
      );
      if (res?.data?.status === true) {
        callBack("success", res?.data);
      }
    } catch (error) {
      const message = error?.response?.data;
      callBack("fail", message);
      console.log(error, "--error--");
    }
  };
};
