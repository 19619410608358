import { checkServerURL } from "../../../helper/helper";
import { API_URL } from "../../../utils/config";
import trashIcon from "../../assets/images/tash-user-icon.png";

export const videoCSV = (videoListData) => {
    const videoDataCSVFormate =
        videoListData &&
        videoListData?.map((item) => {
            return {
                ID: item?.id ? item?.id : "N/A",
                UserName: item?.userDetails?.user_name
                    ? item?.userDetails?.user_name
                    : "N/A",
                Email: item?.userDetails?.user_email
                    ? item?.userDetails?.user_email
                    : "N/A",
                Picture: item?.userDetails?.user_picture
                    ? checkServerURL(API_URL, item?.userDetails?.picture)
                    : "N/A",
                Name: item?.name ? item?.name : "N/A",
                Title: item?.title ? item?.title : "N/A",
                Summary: item?.summary ? item?.summary : "N/A",
                Thumbnail: item?.thumbnail
                    ? checkServerURL(API_URL, item?.thumbnail)
                    : "N/A",
                FileDuration: item?.file_duration ? item?.file_duration : "N/A",
                Created_at: item?.created_at,
                Updated_at: item?.updated_at,
            };
        });
    let videoDataCSV = {
        filename: "videos-list",
        data: videoDataCSVFormate,
    };
    return videoDataCSV;
}

export const screenshotCSV = (screenshotListData) => {
    const screenshotDataCSVFormate =
        screenshotListData &&
        screenshotListData?.map((item) => {
            return {
                ID: item?.id ? item?.id : "N/A",
                UserName: item?.userDetails?.name ? item?.userDetails?.name : "N/A",
                Email: item?.userDetails?.email ? item?.userDetails?.email : "N/A",
                Picture: item?.userDetails?.picture
                    ? checkServerURL(API_URL, item?.userDetails?.picture)
                    : "N/A",
                Name: item?.name ? item?.name : "N/A",
                Title: item?.title ? item?.title : "N/A",
                Summary: item?.summary ? item?.summary : "N/A",
                Thumbnail: item?.thumbnail
                    ? checkServerURL(API_URL, item?.thumbnail)
                    : "N/A",
                FileSize: item?.file_size ? item?.file_size : "N/A",
                Created_at: item?.created_at,
                Updated_at: item?.updated_at,
            };
        });
    let screenshotDataCSV = {
        filename: "screenshots-list",
        data: screenshotDataCSVFormate,
    };
    return screenshotDataCSV;
}

export const confirmationModalMessage = ({ action, type }) => {
    switch (action) {
        case "SCREENSHOT_DELETE":
            return {
                title: <>Would you like to permanently <br />delete this screenshot?</>,
                message: <>Once deleted, this screenshot will<br />no longer be accessible.</>,
                type: action,
                icon: trashIcon,
                tab: type
            };
        case "MULTI_SCREENSHOT_DELETE":
            return {
                title: <>Would you like to permanently <br />delete this screenshot?</>,
                message: <>Once deleted, these screenshot will<br />no longer be accessible.</>,
                type: action,
                icon: trashIcon,
                tab: type
            };
        case "VIDEO_DELETE":
            return {
                title: <>Would you like to permanently <br />delete this video?</>,
                message: <>Once deleted, this video will<br />no longer be accessible.</>,
                type: action,
                icon: trashIcon,
                tab: type
            };
        case "MULTI_VIDEO_DELETE":
            return {
                title: <>Would you like to permanently <br />delete selected video?</>,
                message: <>Once deleted, these video will<br />no longer be accessible.</>,
                type: action,
                icon: trashIcon,
                tab: type
            };
        default:
            return null;
    }
}