import React, { useEffect } from "react";

const AdSenseAd = ({ client, slot, format }) => {
  useEffect(() => {
    const installGoogleAds = () => {
      const elem = document.createElement("script");
      elem.src =
        "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4424794709901498";
      elem.async = true;
      elem.defer = true;
      document.body.insertBefore(elem, document.body.firstChild);
    };
    installGoogleAds();
    if (window.adsbygoogle && !window.adsbygoogle.loaded) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, [client, slot, format]);

  return (
    <div>
      {/* Google AdSense Code */}
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client={client}
        data-ad-slot={slot}
        data-ad-format={format}
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default AdSenseAd;
