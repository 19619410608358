import React from "react";
import getExtArrowIcon from "../../../assets/images/get-ext-arrow.png";
import { Link } from "react-router-dom";

const TryScreenGenius = () => {
  return (
    <div className="container ScreenGenius_today">
      <div className="row">
        <span>
          <h4>Try Vizam today!</h4>
          <Link
            className="btn btn-primary ScreenGenius_todaybtn"
            to="https://chrome.google.com/webstore/detail/screengenius-screenshot-s/eclnikmacpcandpbfjkjgnoicmlpkkdj"
          >
            Get Chrome Extension
            <img src={getExtArrowIcon} />
          </Link>
        </span>
      </div>
    </div>
  );
};

export default TryScreenGenius;
