import React, { useState } from "react";
import facebookIcon from "../../assets/images/home/facebook.png";
import instagramIcon from "../../assets/images/home/instagram.png";
import twitterIcon from "../../assets/images/home/twitter.png";
import linkedInIcon from "../../assets/images/home/linkedin.png";
import quixyLogoNew from "../../assets/images/home/Quixy_Logo_new.png";
import footerLogo from "../../assets/images/home/footer-logo.png";
import { Link } from "react-router-dom";
import UserFeedBackModal from "../Modals/UserFeedBackModal";

const Footer = () => {
  const [showFeedbackModal, setFeedbackModal] = useState(false);
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="quixy-dashboard-footer-container">
        <div className="row">
          <div className="col-lg-4">
            <span>
              <img className="sitetitle" src={footerLogo} />
              <p>
                <span>Screenshots &amp; Screen recordings </span>
                <span>with a dash of your unique personality.</span>
              </p>
            </span>
          </div>
          <div className="col-lg-4">
            <span>
              <ul>
                <li>
                  {" "}
                  <Link to="/about-us">About us</Link>
                </li>
                <li>
                  {" "}
                  <Link
                    to="#"
                    onClick={() => setFeedbackModal(!showFeedbackModal)}
                  >
                    Feedback
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="/privacy-policy" target="_blank">
                    Privacy
                  </Link>
                </li>
              </ul>
            </span>
          </div>
          <div className="col-lg-4">
            <span>
              <h5>Contact us</h5>
              <p>Vividminds Technologies Private Limited</p>
              <p>
                {" "}
                <a className="support-email" href="mailto:support@vizam.ai">
                  support@vizam.ai
                </a>
              </p>
            </span>
          </div>
        </div>
      </div>
      <div className="social_icon_copy_right">
        <div className="quixy-dashboard-footer-container">
          <div className="row">
            <div className="col-lg-4">
              <span>
                <img src={facebookIcon} title="Facebook"/>
              </span>
              <span>
                {" "}
                <img src={instagramIcon} title="Instagram"/>
              </span>
              <span>
                {" "}
                <img src={twitterIcon} title="Twitter(X)"/>
              </span>
              <span>
                {" "}
                <img src={linkedInIcon} title="LinkedIn"/>
              </span>
            </div>
            <div className="col-lg-4">
              {" "}
              <span className="Copyright">
                Copyright ® {currentYear} Company All rights Reserved
              </span>
            </div>
            <div className="col-lg-4">
              <span className="Quixy_Logo">
                <img src={quixyLogoNew} />
              </span>
            </div>
          </div>
        </div>
      </div>
      {showFeedbackModal && (
        <UserFeedBackModal
          showModal={showFeedbackModal}
          closeModal={setFeedbackModal}
        />
      )}
    </div>
  );
};

export default Footer;
