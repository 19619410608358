import React from "react";
import cameraIcon from "../../../assets/images/home/user-activitiy.png";
import activatityImg from "../../../assets/images/home/activatity.png";
import editIcon from "../../../assets/images/home/user-activitiy.png";
import shareIcon from "../../../assets/images/home/user-activitiy.png";
import menuIcon from "../../../assets/images/home/user-activitiy.png";

const ScreenshotSmarter = () => {
  return (
    <div className="container" id="screenshot-tab">
      <div className="row">
        <div className="section_title">
          <h1>Screenshot Smarter. Not Harder.</h1>
          <p>Spark inspiration, share ideas, &amp; ignite conversations</p>
        </div>
        <div className="col-lg-6 d-flex flex-column justify-content-center conversations-mid screenshots">
          <img src={activatityImg} />
        </div>
        <div className="col-lg-6 conversations-mid">
          <span className="screenshot_smarter">
            <img src={cameraIcon} />
            <h5>Capture screen like a boss!</h5>
            <p>Snap full screen area, visible area, or select as your wish</p>
          </span>
          <span className="screenshot_smarter">
            <img src={editIcon} />
            <h5>Edit & Annotate to your heart’s content</h5>
            <p>
              Crop it. Add text & highlights, blur & other effects with ease
            </p>
          </span>
          <span className="screenshot_smarter">
            <img src={shareIcon} />
            <h5>Be ready to share with a flick.</h5>
            <p>Upload to cloud, share via link, or by email</p>
          </span>
          <span className="screenshot_smarter">
            <img src={menuIcon} />
            <h5>All media roads lead to dashboard!</h5>
            <p>View, Access, Manage screenshots through dashboard</p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ScreenshotSmarter;
