import React from 'react';
import { getDropBoxLogin } from '../../../../redux/actions/social-share';

const DropBoxLogin = ({ apiKey, redirect_Url, children, callback }) => {
    const handleDropBoxLogin = () => {
        const dropBoxAuthUrl = `https://www.dropbox.com/oauth2/authorize?client_id=${apiKey}&response_type=code&redirect_uri=${redirect_Url}`;
        const width = 600;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        const popup = window.open(
            dropBoxAuthUrl,
            "DropBox",
            `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,resizable=yes`
        );

        async function handleAuthResponse(event) {
            const { code } = event?.data;
            if (code) {
                getDropBoxLogin(code, (type, res) => {
                    if (type === 'success') {
                        callback('success', res);
                        window.removeEventListener("message", handleAuthResponse);
                        popup.close();
                    } else {
                        callback('failed', res);
                        window.removeEventListener("message", handleAuthResponse);
                        popup.close();
                    }
                })
            } else {
                callback('failed', null);
            }
        }
        window.addEventListener("message", handleAuthResponse);
    };

    return (
        <div onClick={handleDropBoxLogin}>
            {children}
        </div>
    );
};

DropBoxLogin.defaultProps = {
    apiKey: "",
    redirect_Url: "",
    callback: function () { },
}


export default DropBoxLogin;