import React, { useState } from "react";
import uninsatllEmoji from "../../assets/images/home/uninstallemoji.png";
import logoImg from "../../assets/images/home/logo.png";
import lightLoader from "../../assets/images/light-loader.gif";
import { sendFeedBack } from "../../redux/actions/manageScreenshots";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { failureMessagePopup, successMessagePopup } from "../../helper/helper";

const Unintall = () => {
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState("");
  const [formData, setFormdata] = useState({
    senderEmail: "",
    whyUninstall: "",
    otherTool: "",
    whatToImprove: "",
    userMessage: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const unInstallSchema = ({ formData, setFormErrors }) => {
    const { senderEmail, whyUninstall, otherTool, whatToImprove, userMessage } =
      formData;

    let regex =
      /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    let valid = true;

    const errors = {
      emailError: "",
      feedBackError: "",
    };

    if (!senderEmail) {
      errors.emailError = "Email cannot be empty!";
      valid = false;
    } else if (!regex.test(senderEmail)) {
      errors.emailError = "Invalid email address!";
      valid = false;
    }
    if (
      whyUninstall.trim("") === "" &&
      whyUninstall.trim("") === "" &&
      otherTool.trim("") === "" &&
      whatToImprove.trim("") === "" &&
      userMessage.trim("") === ""
    ) {
      errors.feedBackError = "You must have to fill at least one field!";
      valid = false;
    }
    setFormErrors(errors);
    return valid;
  };

  const handleSubmit = () => {
    if (unInstallSchema({ formData, setFormErrors })) {
      dispatch(
        sendFeedBack(formData, (type, res) => {
          if (type === "success") {
            successMessagePopup(
              "Submited Successfully!",
              "Thank you for your feedback! It has been successfully submitted."
            );
          } else if (type === "failed") {
            failureMessagePopup("Error Message!", "Something went wrong.");
          }
        })
      );
    }
  };

  return (
    <div className="uninstall-page" id="root">
      <div className="header_section">
        <div className="container top_container">
          <header className="ScreenGenius-header">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                  <img
                    className="sitetitle"
                    src={logoImg}
                    height="46"
                    alt="CoolBrand"
                  />
                </Link>
              </div>
            </nav>
          </header>
          <div className="container-fluid">
            <div className="row">
              <div className="star_emoji">
                <span className="thankEmoji">
                  <span className="emoji">
                    <img src={uninsatllEmoji} />
                    <span className="emoji_content"></span>
                    <h5>
                      Vizam successfully uninstalled from your device now!
                    </h5>
                    <p>
                      Your experience matters to us. Would you mind taking few
                      minutes to share your feedback on our product?
                    </p>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container screen_recording_game">
        <div className="row">
          <div className="col-lg-12 conversations-mid extension_screen">
            <div
              className="section_title"
              style={{
                textAlign: "center",
                marginTop: "5%",
                marginBottom: "2%",
              }}
            >
              <h5>Share your feedback with us</h5>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputEmail4">Email Address</label>
                <input
                  className="form-control"
                  id="inputEmail4"
                  type="email"
                  name="senderEmail"
                  placeholder="Enter"
                  defaultValue={formData?.senderEmail}
                  onChange={(e) => handleChange(e)}
                  maxLength={255}
                />
                {formErrors?.emailError && (
                  <span className="text-danger account-dec d-flex">
                    {formErrors?.emailError}
                  </span>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputEmail4">
                  Why did you uninstall Vizam?
                </label>
                <textarea
                  className="form-control"
                  id="inputEmail4"
                  type="text"
                  name="whyUninstall"
                  placeholder="Enter"
                  defaultValue={formData?.whyUninstall}
                  onChange={(e) => handleChange(e)}
                ></textarea>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputPassword4">
                  Are you using any other tool instead of Vizam?
                </label>
                <textarea
                  className="form-control"
                  id="inputPassword4"
                  type="text"
                  name="otherTool"
                  placeholder="Enter"
                  defaultValue={formData?.otherTool}
                  onChange={(e) => handleChange(e)}
                ></textarea>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputEmail4">
                  What can we do to improve Vizam?
                </label>
                <textarea
                  className="form-control"
                  id="inputEmail4"
                  type="text"
                  name="whatToImprove"
                  placeholder="Enter"
                  defaultValue={formData?.whatToImprove}
                  onChange={(e) => handleChange(e)}
                ></textarea>
                {formErrors?.feedBackError && (
                  <span className="text-danger account-dec d-flex">
                    {formErrors?.feedBackError}
                  </span>
                )}
              </div>
            </div>
            <div className="btn_section_row">
              <img className="loader-image" src={lightLoader} />
              <button
                className="btn btn-primary btn_cancel_submit submitbtn"
                type="submit"
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="col-lg-12 thank-you-screen">
            <div
              className="thank-you-screen-title"
              style={{
                textAlign: "center",
                marginTop: "5%",
                marginBottom: "5%",
              }}
            >
              <span>Your Feedback is received successfully.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unintall;
