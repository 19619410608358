import React from "react";
import { Link } from "react-router-dom";

const Statistics = ({ statistics, viewTitle, viewURl }) => {
    return (
        <div className="table-statistics">
            <div className="statistics-header d-flex justify-content-between align-items-center">
                <h5>Statistics</h5>
                {viewTitle && (
                    <Link className="nav-link" to={viewURl}>
                        {viewTitle}
                    </Link>
                )}
            </div>
            <div className="statistics-content">
                {statistics &&
                    statistics?.map((item, index) => {
                        return (
                            <div
                                className="statistics-card d-flex align-items-center"
                                key={index}
                            >
                                <h6 className="d-flex align-items-center">
                                    {item?.icon && <img src={item?.icon} />}
                                    {item?.value !== 'undefined' ? item?.value : 0}
                                </h6>
                                <span>{item?.label}</span>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

Statistics.defaultProps = {
    statistics: [], 
    viewTitle: "", 
    viewURl: "", 
};

export default Statistics;
