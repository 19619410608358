import React from "react";

const Filters = ({ filterList }) => {
    return (
        <div className="management-filters">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                {filterList?.map((filter, index) => {
                    return (
                        <li className="nav-item" role="presentation" key={index}>
                            <button
                                className={`nav-link btn management-filter-btn ${filter?.className}`}
                                id={`pills-${filter?.id}-tab`}
                                type="button"
                                data-bs-toggle="pill"
                                data-bs-target={`#${filter?.id}-pill`}
                                role="tab"
                                aria-controls={filter?.id}
                                aria-selected="true"
                                onClick={() => filter.onClick && filter.onClick()}
                            >
                                {filter?.title}
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

Filters.defaultProps = {
    filterList: []
};

export default Filters;
