import axios from "axios";
import { API_URL } from "../../../utils/config";
import { GET_ALL_ADMIN_SCREENSHOT, LOADING_ADMIN_SCREENSHORT, NOT_FOUND_ADMIN_SCREENSHOT } from "../type";

// get all screenshot by admin id
export const getAllScreenshotsListAdmin = (adminId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: LOADING_ADMIN_SCREENSHORT,
            });

            let data = JSON.stringify({
                admin_id: adminId,
            });

            let config = {
                maxBodyLength: Infinity,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const res = await axios.post(
                `${API_URL}/admin/screenshots/list`,
                data,
                config
            );
            if (res?.data?.status === true) {
                if (res?.data?.data?.length <= 0) {
                    dispatch({
                        type: NOT_FOUND_ADMIN_SCREENSHOT,
                    })
                } else {
                    dispatch({
                        type: GET_ALL_ADMIN_SCREENSHOT,
                        payload: res?.data,
                    });
                }
            }
        } catch (error) {
            console.log(error, "---error---");
        }
    };
};

// edit screenshot admin by user id and screenshot id with admin id
export const editScreenshotAdmin = (payload, callBack) => {
    return async (dispatch) => {
        try {
            let data = JSON.stringify({
                user_id: payload?.user_id,
                admin_id: payload?.admin_id,
                name: payload?.name,
                title: payload?.title,
                summary: payload?.summary,
                id: payload?.id,
                tags: payload?.tags,
            });

            let config = {
                maxBodyLength: Infinity,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const res = await axios.post(
                `${API_URL}/admin/screenshots/rename`,
                data,
                config
            );
            if (res?.data?.status === true) {
                callBack("success", res?.data);
            }
        } catch (error) {
            const message = error?.response?.data;
            callBack("failed", message);
            console.log("---error---", error);
        }
    };
};

// search screenshot by name
export const SearchScreenshotAdmin = (payload) => {
    return async (dispatch) => {
        try {
            let data = JSON.stringify({
                admin_id: payload?.admin_id,
            });

            let config = {
                maxBodyLength: Infinity,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            let searchUrl = "";
            if (payload?.query === "suspendUser") {
                searchUrl += `${payload?.text}&status=1`;
            } else {
                searchUrl += `${payload?.text}`;
            }

            const res = await axios.post(
                `${API_URL}/admin/screenshots/search-list?search=${searchUrl}`,
                data,
                config
            );
            if (res?.data?.status === true) {
                dispatch({
                    type: GET_ALL_ADMIN_SCREENSHOT,
                    payload: res?.data,
                });
            }
        } catch (error) {
            const message = error?.response?.data;
            // callBack("fail", message);
            console.log("---error---", error);
        }
    };
};


// Delete screenshot admin by id and screenshot id
export const deleteScreenshotAdmin = (payload, callBack) => {
    return async (dispatch) => {
        try {
            let data = JSON.stringify({
                admin_id: payload?.admin_id,
                id: payload?.id
            });

            let config = {
                maxBodyLength: Infinity,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            const res = await axios.delete(
                `${API_URL}/admin/screenshots/delete-many-ids`,
                config
            );
            if (res?.data?.status === true) {
                callBack("success", res?.data);
            }
        } catch (error) {
            const message = error?.response?.data;
            callBack("fail", message);
            console.log("---error---", error);
        }
    };
};
