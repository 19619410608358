import axios from "axios";
import {
    LOADING_STATISTICS,
    GET_ALL_STATISTICS,
} from "../../actions/type";
import { API_URL } from "../../../utils/config";

// get statistics list by admin id
export const getStatisticsListAdmin = (adminID) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: LOADING_STATISTICS,
            });

            let data = JSON.stringify({
                admin_id: adminID,
            });

            let config = {
                maxBodyLength: Infinity,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const res = await axios.post(`${API_URL}/admin/statistics/data`, data, config);
            if (res?.data?.status === true) {
                dispatch({
                    type: GET_ALL_STATISTICS,
                    payload: res?.data,
                });
            }
        } catch (err) {
            console.log(err, "---Error---");
        }
    };
};
