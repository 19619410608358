import React from "react";

const FrequentlyAskedQuestions = () => {
  return (
    <div className="container-fluid frequently_asked_questions">
      <div className="container" id="frequently-asked-questions">
        <div className="row">
          <div className="section_title">
            <h1>Frequently Asked Questions</h1>
          </div>
          <div className="col-lg-6">
            <span>
              <h6>
                Is Vizam free? Any number limit on the screenshots &amp;
                recordings I do?
              </h6>
              <p>
                Yes, it's absolutely free as of now, so take advantage of that
                as much as you can.
              </p>
            </span>
            <span>
              <h6>How can I upgrade to the premium version?</h6>
              <p>
                Premium version is on the card. As this is a beta launch, we'll
                soon notify you once upgrade is available.
              </p>
            </span>
            <span>
              <h6>Can I access Vizam from all of my devices?</h6>
              <p>
                A big yes to that. Access from any device without any
                restrictions.
              </p>
            </span>
          </div>
          <div className="col-lg-6">
            <span>
              <h6>
                Can I be worry-free that my data is safe with Vizam?
              </h6>
              <p>
                No doubt for that. Your data safety &amp; security is our top
                priority. You can relax, your information is in safe hands.
              </p>
            </span>
            <span>
              <h6>What if I got a special feature request?</h6>
              <p>
                We're all ears for that. Though we can't promise the timelines
                but we pay attention to all ideas. Please submit your request in
                feedback section.
              </p>
            </span>
            <span>
              <h6>Till when my generated share links will be valid?</h6>
              <p>
                All your screenshots &amp; screen recordings links are here to
                stay, as long as you want to keep them in public.
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
