import {
    ADMIN_LOADING_TAGS,
    ADMIN_TAG_LIST,
    NOT_FOUND_ADMIN_TAG,
    ADMIN_LOADING_USERS_TAGS,
    ADMIN_USERS_TAG_LIST,
    NOT_FOUND_ADMIN_USERS_TAG
} from "../../actions/type";

const INITIAL_STATE = {
    list: [{}],
    is_loading: false,
    not_found: false,
    users_tags_list: [{}],
    is_loading_users_tags: false,
    not_found_users_tags: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_LOADING_TAGS:
            return { ...state, is_loading: true, not_found: false };
        case ADMIN_TAG_LIST:
            return {
                ...state,
                list: action.payload,
                is_loading: false,
                not_found: false,
            };
        case NOT_FOUND_ADMIN_TAG:
            return { ...state, list: [], is_loading: false, not_found: true };
        case ADMIN_LOADING_USERS_TAGS:
            return { ...state, is_loading_users_tags: true, not_found_users_tags: false };
        case ADMIN_USERS_TAG_LIST:
            return {
                ...state,
                users_tags_list: action.payload,
                is_loading_users_tags: false,
                not_found_users_tags: false,
            };
        case NOT_FOUND_ADMIN_USERS_TAG:
            return { ...state, users_tags_list: [], is_loading_users_tags: false, not_found_users_tags: true };
        default:
            return state;
    }
};
