import React from 'react';
import vizamBnnerImg from '../../../assets/images/home/vizam-banner.png';
import closeIcon from '../../../assets/images/home/close-cross-icon.png';

const NewFeaturePopup = ({ isShowModal, closeModal }) => {
    return (
        <div id="vizam-popup">
            <div
                id="quix-dashboard-overlay"
                style={{
                    display: !isShowModal ? "none" : "block",
                }}
            ></div>
            <div className="vizam-popup-modal">
                <div className='vizam-banner-card'>
                    <img className='close-vizam-modal' onClick={()=>closeModal(false)} src={closeIcon}/>
                    <img src={vizamBnnerImg} />
                </div>
            </div>
        </div>
    );
};

export default NewFeaturePopup;