import {
    LOADING_USERS,
    LOADING_SUSPEND_USERS,
    GET_ALL_USERS,
    NOT_FOUND_ADMIN_USERS,
    GET_ALL_SUSPEND_USERS,
    UPDATE_USER_BY_ID,
    GET_ADMIN_USER_BY_ID,
    NOT_FOUND_SUSPEND_USERS,
} from "../../actions/type";
const INITIAL_STATE = {
    list: [{}],
    suspend_users_list: [{}],
    user_details:[{}],
    update_user: [{}],
    is_loading: false,
    is_loading_suspend_users: false,
    not_found: false,
    not_found_suspend_users: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOADING_USERS:
            return { ...state, is_loading: true };
        case GET_ALL_USERS:
            return {
                ...state,
                list: action.payload,
                is_loading: false,
                not_found: false,
            };
        case GET_ADMIN_USER_BY_ID:
            return {
                ...state,
                user_details: action.payload,
                is_loading: false,
                not_found: false,
            };
        case NOT_FOUND_ADMIN_USERS:
            return {
                ...state,
                is_loading: false,
                list: [],
                not_found: true,
            };
        case LOADING_SUSPEND_USERS:
            return {
                ...state,
                is_loading_suspend_users: true,
                not_found_suspend_users: false,
            };
        case GET_ALL_SUSPEND_USERS:
            return {
                ...state,
                suspend_users_list: action.payload,
                is_loading_suspend_users: false,
                not_found_suspend_users: false,
            };
        case NOT_FOUND_SUSPEND_USERS:
            return {
                ...state,
                is_loading_suspend_users: false,
                suspend_users_list: [],
                not_found_suspend_users: true,
            };
        case UPDATE_USER_BY_ID:
            return {
                ...state,
                update_user: action.payload,
                is_loading: false,
                not_found: false,
            };
        default:
            return state;
    }
};
