import React from "react";
import HeaderSection from "./Sections/HeaderSection";
import ScreenCaptures from "./Sections/ScreenCaptures";
import ScreenshotSmarter from "./Sections/ScreenshotSmarter";
import ScreenRecordingGame from "./Sections/ScreenRecordingGame";
import DrumrollSection from "./Sections/DrumrollSection";
import FrequentlyAskedQuestions from "./Sections/FrequentlyAskedQuestions";
import TryScreenGenius from "./Sections/TryScreenGenius";
import FooterSection from "./Sections/FooterSection";
import "./style.css";

const Home = () => {
  return (
    <div className="home-page" id="root">
      {/* Header Section */}
        <HeaderSection />
      {/* Turn Screen Captures */}
        <ScreenCaptures />
      {/* Screenshot Smarter */}
        <ScreenshotSmarter />
      {/* Take your Screen Recording Game */}
        <ScreenRecordingGame />      
      {/* Drumroll Section */}
        <DrumrollSection />
      {/* Frequently Asked Questions */}
        <FrequentlyAskedQuestions />
      {/* Try ScreenGenius today */}
        <TryScreenGenius />
      {/* Footer */}
        <FooterSection />
    </div>
  );
};

export default Home;
