import React, { useEffect, useState } from "react";
import AdminLayout from "../../layout/adminLayout";
import dummyImg from "../../../assets/images/dummy-image.jpg";
import { checkServerURL } from "../../../helper/helper";
import { API_URL, localStoragePrefix } from "../../../utils/config";
import {
    errorMessageModal,
    successMessageModal,
} from "../../components/Modal/MessagesModal";
import { connect, useDispatch } from "react-redux";
import {
    getExtensionDetailsAdmin,
    updateExtensionSettingsAdmin,
} from "../../../redux/actions/admin/extension";

const ManageExtensionSettings = ({ extensionDetails }) => {
    const adminID = localStorage.getItem(
        localStoragePrefix + "screenGeniusUserID"
    );
    const extData =
        extensionDetails?.data?.extensionDetails &&
        extensionDetails?.data?.extensionDetails;

    const dispatch = useDispatch();
    useEffect(() => {
        if (adminID) {
            dispatch(getExtensionDetailsAdmin(Number(adminID)));
        }
    }, [adminID]);

    let fileFormat = ["image/jpg", "image/jpeg", "image/png"];
    const [uploadedLogo, setUploadedLogo] = useState(null);
    const [uploadedPoweredLogo, setUploadedPoweredLogo] = useState(null);
    const [uploadedExtraLogo, setUploadedExtraLogo] = useState(null);

    const initialState = {
        alt: "logo",
        src:
            !extData?.extension_logo ||
                extData?.extension_logo === "null" ||
                extData?.extension_logo === null
                ? dummyImg
                : checkServerURL(API_URL, extData?.extension_logo),
    };

    const initialStatePoweredLogo = {
        altPowered: "power logo",
        srcPoweredLogo:
            !extData?.powered_by_logo ||
                extData?.powered_by_logo === "null" ||
                extData?.powered_by_logo === null
                ? dummyImg
                : checkServerURL(API_URL, extData?.powered_by_logo),
    };

    const initialStateExtraLogo = {
        altExtralogo: "logo",
        srcExtraLogo:
            !extData?.logo || extData?.logo === "null" || extData?.logo === null
                ? dummyImg
                : checkServerURL(API_URL, extData?.logo),
    };

    const [{ alt, src }, setPreview] = useState(initialState);

    const [{ altPoweredLogo, srcPoweredLogo }, setPreviewPoweredLogo] = useState(
        initialStatePoweredLogo
    );

    const [{ altExtraLogo, srcExtraLogo }, setPreviewExtraLogo] = useState(
        initialStatePoweredLogo
    );

    const initialValues = {
        extension_name: "",
        capture: {
            title: "",
            fullPage: "",
            visiblePart: "",
            selectArea: "",
        },
        recorder: {
            title: "",
            cameraOnly: "",
            thisTab: "",
            selectArea: "",
            entireScreen: "",
        },
        extension_ui: {
            modelBgColor: "",
            fontColor: "",
            buttonColor: "",
            buttonBgColor: "",
            containerBgColor: "",
        },
    };

    const [formData, setFormData] = useState(initialValues);
    let capture = extData?.capture && JSON.parse(extData?.capture);
    let recorder = extData?.recorder && JSON.parse(extData?.recorder);
    let extension_ui = extData?.extension_ui && JSON.parse(extData?.extension_ui);

    const setDefaultSettings = () => {
        setFormData({
            extension_name: extData?.extension_name || "",
            capture: {
                title: capture?.title || "",
                fullPage: capture?.fullPage || "",
                visiblePart: capture?.visiblePart || "",
                selectArea: capture?.selectArea || "",
            },
            recorder: {
                title: recorder?.title || "",
                cameraOnly: recorder?.cameraOnly || "",
                thisTab: recorder?.thisTab || "",
                selectArea: recorder?.selectArea || "",
                entireScreen: recorder?.entireScreen || "",
            },
            extension_ui: {
                modelBgColor: extension_ui?.modelBgColor || "",
                fontColor: extension_ui?.fontColor || "",
                buttonColor: extension_ui?.buttonColor || "",
                buttonBgColor: extension_ui?.buttonBgColor || "",
                containerBgColor: extension_ui?.containerBgColor || "",
            },
        });
        setPreview(initialState);
        setPreviewPoweredLogo(initialStatePoweredLogo);
        setPreviewExtraLogo(initialStateExtraLogo);
    };

    useEffect(() => {
        setDefaultSettings();
    }, [extData]);

    const handleChangeFile = (event, type) => {
        const { files } = event.target;
        let fileType = files?.[0]?.type;
        if (fileFormat.indexOf(fileType) > -1) {
            if (type === "logo") {
                setPreview(
                    files.length
                        ? {
                            src: URL.createObjectURL(files[0]),
                            alt: files[0].name,
                        }
                        : initialState
                );
                setUploadedLogo(files[0]);
            } else if (type === "powered-logo") {
                setPreviewPoweredLogo(
                    files.length
                        ? {
                            srcPoweredLogo: URL.createObjectURL(files[0]),
                            altPoweredLogo: files[0].name,
                        }
                        : initialStatePoweredLogo
                );
                setUploadedPoweredLogo(files[0]);
            } else if (type === "extra-logo") {
                setPreviewExtraLogo(
                    files.length
                        ? {
                            srcExtraLogo: URL.createObjectURL(files[0]),
                            altExtraLogo: files[0].name,
                        }
                        : initialStateExtraLogo
                );
                setUploadedExtraLogo(files[0]);
            }
        } else {
            errorMessageModal(
                "Unsupported Image Format",
                "Please upload a PNG or JPG file."
            );
        }
    };

    const handleSubmit = () => {
        const payload = {
            ...formData,
            admin_id: Number(adminID),
            logo: uploadedLogo,
            poweredLogo: uploadedPoweredLogo,
            extra_logo: uploadedExtraLogo,
        };
        dispatch(
            updateExtensionSettingsAdmin(payload, (type, res) => {
                if (type === "success") {
                    successMessageModal(
                        "Extension Settings Updated Successfully",
                        "Extension Settings has been Updated Successfully"
                    );
                } else if (type === "failed") {
                    errorMessageModal("Extension Settings Updated", res?.message);
                }
            })
        );
    };

    const handleCancel = () => {
        setDefaultSettings();
    };

    const handleRemovePhoto = (type) => {
        if (type === "logo") {
            setUploadedLogo(null);
            setPreview({
                alt: "logo",
                src: dummyImg,
            });
        } else if (type === "powered-logo") {
            setUploadedPoweredLogo(null);
            setPreviewPoweredLogo({
                altPoweredLogo: "logo",
                srcPoweredLogo: dummyImg,
            });
        } else if (type === "extra-logo") {
            setUploadedExtraLogo(null);
            setPreviewExtraLogo({
                altExtraLogo: "logo",
                srcExtraLogo: dummyImg,
            });
        }
    };

    return (
        <div className="manage-extension-settings">
            <AdminLayout pageTitle={"extension-settings"}>
                <div className="extension-settings-content">
                    <div className="table-management-section d-flex justify-content-between col-md-10">
                        <div className="management-header mb-5">
                            <h4>Extension static Ui & Text Settings</h4>
                            <p>
                                You can manage pricing plan, can be create new plan, edit and
                                view the plan.
                            </p>
                        </div>
                        <div className="ext-setting-btns">
                            <button
                                className="btn theme-blue-btn me-2"
                                onClick={() => handleSubmit()}
                            >
                                Save & Continue
                            </button>
                            <button
                                className="btn theme-cancel-btn"
                                onClick={() => handleCancel()}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                    <div className="extension-name mb-4">
                        <h6 className="extension-name-title m-0">Extension Name :</h6>
                        <input
                            className="form-control rounded-2 border-0"
                            type="text"
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    extension_name: e.target.value,
                                })
                            }
                            value={formData?.extension_name}
                        />
                    </div>
                    <div className="extension-settings-outer ext-img-uploader-outer">
                        <div className="extension-settings-card settings-content-card ext-image-uploaders card mb-4">
                            <h5 className="title mb-3">Upload Extension logo</h5>
                            <div className="col-md-12 product-upload-img d-flex gap-3 align-items-center">
                                <div className="card upload-card">
                                    <img src={src} height="100%" width="100%" />
                                    <input
                                        type="file"
                                        name="upload-img"
                                        className="upload-pricing-img"
                                        accept="image/jpg,image/jpeg,image/png"
                                        onChange={(e) => handleChangeFile(e, "logo")}
                                        alt={alt}
                                    />
                                </div>
                                <div className="upload-cover mt-2">
                                    <div className="d-flex gap-2">
                                        <div className="upload-btn upload-card">
                                            <input
                                                type="file"
                                                name="upload-img"
                                                accept="image/jpg,image/jpeg,image/png"
                                                className="upload-pricing-img"
                                                onChange={(e) => handleChangeFile(e, "logo")}
                                            />
                                            <button type="button" className="btn upload-photo-btn">
                                                Upload photo
                                            </button>
                                        </div>
                                        <button
                                            type="button"
                                            className="btn remove-photo-btn"
                                            onClick={() => handleRemovePhoto("logo")}
                                        >
                                            Remove photo
                                        </button>
                                    </div>
                                    <p>at least 132 x 132px PNG, JPEG or JPG file.</p>
                                </div>
                            </div>
                        </div>
                        <div className="extension-settings-card settings-content-card ext-image-uploaders card mb-4">
                            <h5 className="title mb-3">Upload Powered by logo</h5>
                            <div className="col-md-12 product-upload-img d-flex gap-3 align-items-center">
                                <div className="card upload-card">
                                    <img
                                        src={srcPoweredLogo}
                                        height="100%"
                                        width="100%"
                                        alt={altPoweredLogo}
                                    />
                                    <input
                                        type="file"
                                        name="upload-img"
                                        className="upload-pricing-img"
                                        accept="image/jpg,image/jpeg,image/png"
                                        onChange={(e) => handleChangeFile(e, "powered-logo")}
                                    />
                                </div>
                                <div className="upload-cover mt-2">
                                    <div className="d-flex gap-2">
                                        <div className="upload-btn upload-card">
                                            <input
                                                type="file"
                                                name="upload-img"
                                                accept="image/jpg,image/jpeg,image/png"
                                                className="upload-pricing-img"
                                                onChange={(e) => handleChangeFile(e, "powered-logo")}
                                            />
                                            <button type="button" className="btn upload-photo-btn">
                                                Upload photo
                                            </button>
                                        </div>
                                        <button
                                            type="button"
                                            className="btn remove-photo-btn"
                                            onClick={() => handleRemovePhoto("powered-logo")}
                                        >
                                            Remove photo
                                        </button>
                                    </div>
                                    <p>at least 132 x 132px PNG, JPEG or JPG file.</p>
                                </div>
                            </div>
                        </div>
                        <div className="extension-settings-card settings-content-card ext-image-uploaders card mb-4">
                            <h5 className="title mb-3">Logo</h5>
                            <div className="col-md-12 product-upload-img d-flex gap-3 align-items-center">
                                <div className="card upload-card">
                                    <img
                                        src={srcExtraLogo}
                                        height="100%"
                                        width="100%"
                                        alt={altExtraLogo}
                                    />
                                    <input
                                        type="file"
                                        name="upload-img"
                                        className="upload-pricing-img"
                                        accept="image/jpg,image/jpeg,image/png"
                                        onChange={(e) => handleChangeFile(e, "extra-logo")}
                                    />
                                </div>
                                <div className="upload-cover mt-2">
                                    <div className="d-flex gap-2">
                                        <div className="upload-btn upload-card">
                                            <input
                                                type="file"
                                                name="upload-img"
                                                accept="image/jpg,image/jpeg,image/png"
                                                className="upload-pricing-img"
                                                onChange={(e) => handleChangeFile(e, "extra-logo")}
                                            />
                                            <button type="button" className="btn upload-photo-btn">
                                                Upload photo
                                            </button>
                                        </div>
                                        <button
                                            type="button"
                                            className="btn remove-photo-btn"
                                            onClick={() => handleRemovePhoto("extra-logo")}
                                        >
                                            Remove photo
                                        </button>
                                    </div>
                                    <p>at least 132 x 132px PNG, JPEG or JPG file.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="extension-settings-outer ext-settings-card-outer">
                        <div className="extension-settings-card settings-content-card card mb-4">
                            <div className="card-body card-fileds">
                                <div className="row d-block">
                                    <div className="col-md-12 mb-3">
                                        <label
                                            htmlFor="capture-title"
                                            className="col-sm-12 col-form-label text-start"
                                        >
                                            Capture
                                        </label>
                                        <div className="col-sm-12">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="capture-title"
                                                id="capture-title"
                                                maxLength={255}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        capture: {
                                                            ...formData?.capture,
                                                            title: e.target.value,
                                                        },
                                                    })
                                                }
                                                value={formData?.capture?.title}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label
                                            htmlFor="capture-full-page"
                                            className="col-sm-12 col-form-label text-start"
                                        >
                                            Full Page
                                        </label>
                                        <div className="col-sm-12">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="capture-full-page"
                                                id="capture-full-page"
                                                maxLength={255}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        capture: {
                                                            ...formData?.capture,
                                                            fullPage: e.target.value,
                                                        },
                                                    })
                                                }
                                                value={formData?.capture?.fullPage}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label
                                            htmlFor="capture-visible-part"
                                            className="col-sm-12 col-form-label text-start"
                                        >
                                            Visible Part
                                        </label>
                                        <div className="col-sm-12">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="capture-visible-part"
                                                id="capture-visible-part"
                                                maxLength={255}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        capture: {
                                                            ...formData?.capture,
                                                            visiblePart: e.target.value,
                                                        },
                                                    })
                                                }
                                                value={formData?.capture?.visiblePart}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label
                                            htmlFor="capture-select-area"
                                            className="col-sm-12 col-form-label text-start"
                                        >
                                            Select Area
                                        </label>
                                        <div className="col-sm-12">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="capture-select-area"
                                                id="capture-select-area"
                                                maxLength={255}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        capture: {
                                                            ...formData?.capture,
                                                            selectArea: e.target.value,
                                                        },
                                                    })
                                                }
                                                value={formData?.capture?.selectArea}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="extension-settings-card settings-content-card card mb-4">
                            <div className="card-body card-fileds">
                                <div className="row d-block">
                                    <div className="col-md-12 mb-3">
                                        <label
                                            htmlFor="recorder-title"
                                            className="col-sm-12 col-form-label text-start"
                                        >
                                            Recorder
                                        </label>
                                        <div className="col-sm-12">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="recorder-title"
                                                id="recorder-title"
                                                maxLength={255}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        recorder: {
                                                            ...formData?.recorder,
                                                            title: e.target.value,
                                                        },
                                                    })
                                                }
                                                value={formData?.recorder?.title}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label
                                            htmlFor="recorder-camera-only"
                                            className="col-sm-12 col-form-label text-start"
                                        >
                                            Camera Only
                                        </label>
                                        <div className="col-sm-12">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="recorder-camera-only"
                                                id="recorder-camera-only"
                                                maxLength={255}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        recorder: {
                                                            ...formData?.recorder,
                                                            cameraOnly: e.target.value,
                                                        },
                                                    })
                                                }
                                                value={formData?.recorder?.cameraOnly}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label
                                            htmlFor="recorder-this-tab"
                                            className="col-sm-12 col-form-label text-start"
                                        >
                                            This Tab
                                        </label>
                                        <div className="col-sm-12">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="recorder-this-tab"
                                                id="recorder-this-tab"
                                                maxLength={255}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        recorder: {
                                                            ...formData?.recorder,
                                                            thisTab: e.target.value,
                                                        },
                                                    })
                                                }
                                                value={formData?.recorder?.thisTab}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label
                                            htmlFor="recorder-select-area"
                                            className="col-sm-12 col-form-label text-start"
                                        >
                                            Select Area
                                        </label>
                                        <div className="col-sm-12">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="recorder-select-area"
                                                id="recorder-select-area"
                                                maxLength={255}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        recorder: {
                                                            ...formData?.recorder,
                                                            selectArea: e.target.value,
                                                        },
                                                    })
                                                }
                                                value={formData?.recorder?.selectArea}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label
                                            htmlFor="recorder-entire-screen"
                                            className="col-sm-12 col-form-label text-start"
                                        >
                                            Entire Screen
                                        </label>
                                        <div className="col-sm-12">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="recorder-entire-screen"
                                                id="recorder-entire-screen"
                                                maxLength={255}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        recorder: {
                                                            ...formData?.recorder,
                                                            entireScreen: e.target.value,
                                                        },
                                                    })
                                                }
                                                value={formData?.recorder?.entireScreen}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="extension-settings-card settings-content-card card mb-4">
                            <div className="card-body card-fileds">
                                <div className="row d-block">
                                    <div className="col-md-12 mb-3">
                                        <label
                                            htmlFor="model-bg-color"
                                            className="col-sm-12 col-form-label text-start"
                                        >
                                            Model Background Color
                                        </label>
                                        <div className="col-sm-12 d-flex align-items-center color-options gap-2">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="model-bg-color"
                                                id="model-bg-color"
                                                maxLength={255}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        extension_ui: {
                                                            ...formData?.extension_ui,
                                                            modelBgColor: e.target.value,
                                                        },
                                                    })
                                                }
                                                value={formData?.extension_ui?.modelBgColor}
                                            />
                                            <div
                                                className="color-bar-outer"
                                                style={{
                                                    background: formData.extension_ui.modelBgColor,
                                                }}
                                                title="Select Color"
                                            >
                                                <input
                                                    className="color-bar"
                                                    value={formData?.extension_ui?.modelBgColor}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            extension_ui: {
                                                                ...formData?.extension_ui,
                                                                modelBgColor: e.target.value,
                                                            },
                                                        })
                                                    }
                                                    type="color"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label
                                            htmlFor="font-color"
                                            className="col-sm-12 col-form-label text-start"
                                        >
                                            Font Color
                                        </label>
                                        <div className="col-sm-12 d-flex align-items-center color-options gap-2">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="font-color"
                                                id="font-color"
                                                maxLength={255}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        extension_ui: {
                                                            ...formData?.extension_ui,
                                                            fontColor: e.target.value,
                                                        },
                                                    })
                                                }
                                                value={formData?.extension_ui?.fontColor}
                                            />
                                            <div
                                                className="color-bar-outer"
                                                style={{ background: formData.extension_ui.fontColor }}
                                                title="Select Color"
                                            >
                                                <input
                                                    className="color-bar"
                                                    value={formData?.extension_ui?.fontColor}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            extension_ui: {
                                                                ...formData?.extension_ui,
                                                                fontColor: e.target.value,
                                                            },
                                                        })
                                                    }
                                                    type="color"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label
                                            htmlFor="button-color"
                                            className="col-sm-12 col-form-label text-start"
                                        >
                                            Button Color
                                        </label>
                                        <div className="col-sm-12 d-flex align-items-center color-options gap-2">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="button-color"
                                                id="button-color"
                                                maxLength={255}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        extension_ui: {
                                                            ...formData?.extension_ui,
                                                            buttonColor: e.target.value,
                                                        },
                                                    })
                                                }
                                                value={formData?.extension_ui?.buttonColor}
                                            />
                                            <div
                                                className="color-bar-outer"
                                                style={{
                                                    background: formData.extension_ui.buttonColor,
                                                }}
                                                title="Select Color"
                                            >
                                                <input
                                                    className="color-bar"
                                                    value={formData?.extension_ui?.buttonColor}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            extension_ui: {
                                                                ...formData?.extension_ui,
                                                                buttonColor: e.target.value,
                                                            },
                                                        })
                                                    }
                                                    type="color"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label
                                            htmlFor="button-bg-color"
                                            className="col-sm-12 col-form-label text-start"
                                        >
                                            Button Background Color
                                        </label>
                                        <div className="col-sm-12 d-flex align-items-center color-options gap-2">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="button-bg-color"
                                                id="button-bg-color"
                                                maxLength={255}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        extension_ui: {
                                                            ...formData?.extension_ui,
                                                            buttonBgColor: e.target.value,
                                                        },
                                                    })
                                                }
                                                value={formData?.extension_ui?.buttonBgColor}
                                            />
                                            <div
                                                className="color-bar-outer"
                                                style={{
                                                    background: formData.extension_ui.buttonBgColor,
                                                }}
                                                title="Select Color"
                                            >
                                                <input
                                                    className="color-bar"
                                                    value={formData?.extension_ui?.buttonBgColor}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            extension_ui: {
                                                                ...formData?.extension_ui,
                                                                buttonBgColor: e.target.value,
                                                            },
                                                        })
                                                    }
                                                    type="color"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label
                                            htmlFor="container-bg-color"
                                            className="col-sm-12 col-form-label text-start"
                                        >
                                            Container Background Color
                                        </label>
                                        <div className="col-sm-12 d-flex align-items-center color-options gap-2">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="container-bg-color"
                                                id="container-bg-color"
                                                maxLength={255}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        extension_ui: {
                                                            ...formData?.extension_ui,
                                                            containerBgColor: e.target.value,
                                                        },
                                                    })
                                                }
                                                value={formData?.extension_ui?.containerBgColor}
                                            />
                                            <div
                                                className="color-bar-outer"
                                                style={{
                                                    background: formData.extension_ui.containerBgColor,
                                                }}
                                                title="Select Color"
                                            >
                                                <input
                                                    className="color-bar"
                                                    value={formData?.extension_ui?.containerBgColor}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            extension_ui: {
                                                                ...formData?.extension_ui,
                                                                containerBgColor: e.target.value,
                                                            },
                                                        })
                                                    }
                                                    type="color"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminLayout>
        </div>
    );
};

const mapStateToProps = (state) => ({
    extensionDetails: state?.extension?.details,
});

export default connect(mapStateToProps)(ManageExtensionSettings);
