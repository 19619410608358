import React from "react";
import bxsCheckCircleIcon from "../../../assets/images/home/bxs-check-circle.png";
import { Link } from "react-router-dom";

const DrumrollSection = () => {
  return (
    <div className="container-fluid features_in_beta">
      <div className="container" id="pricing-plans-tab">
        <div className="row">
          <div className="section_title">
            <h1>Drumroll please! It's our beta launch</h1>
            <p>
              Ready to make it to the next level? Soon will be launching our
              paid plans, perfect for those looking to scale their use.
            </p>
          </div>
          <div className="features_in_beta_plan">
            <span className="features_in_beta_plan_span">
              <h5>Pricing</h5>
              <p>Completely free for limited period!</p>
              <ul>
                <li>
                  <img src={bxsCheckCircleIcon} />
                  <span>Unlimited Local Captures &amp; Recording</span>
                </li>
                <li>
                  <img src={bxsCheckCircleIcon} />
                  <span>15 Screen Recordings on Cloud</span>
                </li>
                <li>
                  <img src={bxsCheckCircleIcon} />
                  <span>30 Screenshots on Cloud</span>
                </li>
                <li>
                  <img src={bxsCheckCircleIcon} />
                  <span>Editors for both Images and Videos</span>
                </li>
                <li>
                  <img src={bxsCheckCircleIcon} />
                  <span>Share them infinitely</span>
                </li>
              </ul>
              <Link
                className="btn btn-primary chrome-thisbtn"
                to="https://chrome.google.com/webstore/detail/screengenius-screenshot-s/eclnikmacpcandpbfjkjgnoicmlpkkdj"
              >
                Get Chrome Extension
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrumrollSection;
