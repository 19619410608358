import React from "react";
import vidCameraIcon from "../../../assets/images/home/user-activitiy.png";
import editIcon from "../../../assets/images/home/user-activitiy.png";
import shareIcon from "../../../assets/images/home/user-activitiy.png";
import menuIcon from "../../../assets/images/home/user-activitiy.png";
import activatityImg2 from "../../../assets/images/home/activatity2.png";

const ScreenRecordingGame = () => {
  return (
    <div className="container-fluid screen_recording_game">
        <div className="container" id="screen-recording-tab">
          <div className="row">
            <div className="section_title">
              <h1>Take your Screen Recording game up a notch!</h1>
              <p>
                Captivate, educate or entertain. Make people engage with your
                content like never before
              </p>
            </div>
            <div className="col-lg-6 conversations-mid">
              <span className="take_your_screen">
                <img src={vidCameraIcon} />
                <h5>Now record all windows or only the selected ones!</h5>
                <p>With an option to record your camera or current tab</p>
              </span>
              <span className="take_your_screen">
                <img src={editIcon} />
                <h5>Add text, shapes, layers and some sweet beats</h5>
                <p>
                Trim, and edit like nobody’s business.
                </p>
              </span>
              <span className="take_your_screen">
                <img src={shareIcon} />
                <h5>Upload and share with friends, or colleagues</h5>
                <p>Share link, or an email & wow them with your creations</p>
              </span>
              <span className="take_your_screen">
                <img src={menuIcon} />
                <h5>Dashboard makes access easy.</h5>
                <p>Manage  your precious content at one place </p>
              </span>
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-center conversations-mid screenshots">
              <img src={activatityImg2} />
            </div>
          </div>
        </div>
      </div>
  );
};

export default ScreenRecordingGame;
