import { LOADING_ADMIN_SCREENSHORT, GET_ALL_ADMIN_SCREENSHOT, NOT_FOUND_ADMIN_SCREENSHOT } from "../../actions/type";

const INITIAL_STATE = {
  list: [{}],
  is_loading: false,
  not_found: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_ADMIN_SCREENSHORT:
      return { ...state, is_loading: true };
    case GET_ALL_ADMIN_SCREENSHOT:
      return { ...state, list: action.payload, is_loading: false, not_found: false };
    case NOT_FOUND_ADMIN_SCREENSHOT:
      return { ...state, list: [], is_loading: false, not_found: true };
    default:
      return state;
  }
};
