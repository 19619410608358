import React from 'react';
import getExtArrowIcon from "../../../assets/images/get-ext-arrow.png";
import callIcon from "../../../assets/images/home/call-icon.png";
import recordingIcon from "../../../assets/images/home/recode-share.png";
import downloadIcon from "../../../assets/images/home/flight-icon.png";
import synkIcon from "../../../assets/images/home/snap-icon.png";
import { Link } from 'react-router-dom';

const ScreenCaptures = () => {
    return (
        <div className="container-fluid turn_scree_captures_section">
            <div className="row">
            <div className="turn_scree_captures">
                <h5>Turn Screen Captures into Stylish Masterpieces.</h5>
                <p>Start Creating. Start Impressing.</p>
                <Link
                className="btn btn-primary ScreenGenius_todaybtn"
                to="https://chrome.google.com/webstore/detail/screengenius-screenshot-s/eclnikmacpcandpbfjkjgnoicmlpkkdj"
                >
                Get Chrome Extension
                <img src={getExtArrowIcon} />
                </Link>
            </div>
            <div className="turn_scree_captures_feature">
                <span className="camraIcon">
                <img src={callIcon} />
                <p>Dab to fab Snaps!</p>
                </span>
                <span className="otherScreen">
                <img src={recordingIcon} />
                <p>Record. Share. Wow!</p>
                </span>
                <span className="otherScreen">
                <img src={downloadIcon} />
                <p>Seamless Cloud Peace</p>
                </span>
                <span className="otherScreen">
                <img src={synkIcon} />
                <p>Snap. Sync. Access</p>
                </span>
            </div>
            </div>
        </div>
    );
};

export default ScreenCaptures;