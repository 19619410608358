import React, { useEffect, useState } from "react";
import logoIcon from "../../assets/images/logo.png";
import dashboardIcon from "../../assets/images/dashboard-icon.png";
import userIcon from "../../assets/images/user-icon.png";
import mediaIcon from "../../assets/images/media-icon.png";
import pricingIcon from "../../assets/images/pricing-icon.png";
import extensionIcon from "../../assets/images/extension-setting.png";
import generalSettingIcon from "../../assets/images/general-setting-icon.png";
import featuresSettingIcon from "../../assets/images/pro-feature-icon.png";
import membershipIcon from "../../assets/images//membership-icon.png";
import dummyUserIcon from "../../assets/images/dumy-user.jpg";
import logOutIcon from "../../assets/images/logout-icon.png";
import settingIcon from "../../assets/images/setting-icon.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_URL, localStoragePrefix } from "../../../utils/config";
import { connect, useDispatch } from "react-redux";
import {
  getUserDetailsByID,
  logOutUser,
} from "../../../redux/actions/authentication";
import { checkServerURL, failureMessagePopup } from "../../../helper/helper";

const Sidebar = ({ pageTitle, isLoading, userDetails }) => {
  const { pathname } = useLocation();
  const userID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );
  const tokenAuth = localStorage.getItem(
    localStoragePrefix + "screenGeniusAuthToken"
  );
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const userData = userDetails?.data && userDetails?.data;
  const userName = userData?.name && userData?.name;
  const userEmail = userData?.email && userData?.email;
  const userProfile =
    !userData?.picture ||
      userData?.picture === "null" ||
      userData?.picture === null
      ? dummyUserIcon
      : checkServerURL(API_URL, `${userData?.picture}`);
  useEffect(() => {
    if (!tokenAuth) {
      localStorage.removeItem(localStoragePrefix + "screenGeniusAuthToken");
      localStorage.removeItem(localStoragePrefix + "screenGeniusUserID");
      navigator("/login");
      return;
    } else if (tokenAuth) {
      dispatch(getUserDetailsByID(tokenAuth, function () { }));
    }
  }, [dispatch, tokenAuth]);

  // logout user
  const handleLogOutUser = () => {
    const payload = {
      userID: userID,
      accessToken: tokenAuth,
    };
    isLoading(true);
    dispatch(
      logOutUser(payload, (res, type) => {
        if (type === "success") {
          localStorage.removeItem(localStoragePrefix + "screenGeniusAuthToken");
          localStorage.removeItem(localStoragePrefix + "screenGeniusUserID");
          localStorage.clear();
          setTimeout(() => {
            isLoading(false);
            navigator("/login");
          }, 600);
        } else if (type === "fail") {
          isLoading(false);
          failureMessagePopup("Error message", "Something went wrong!");
        }
      })
    );
  };

  return (
    <div className="quix-admin-sidebar">
      <div className="quix-admin-nav-content">
        <div className="quix-admin-logo">
          <Link to={"/dashboard"}>
            <img src={logoIcon} />
          </Link>
        </div>
        <ul className="quix-admin-menu-ul" id="quix-admin-nav-menu">
          <li
            className={`quix-admin-menu ${pageTitle === "admin-dashboard" ||
              pathname?.includes("/admin/dashboard")
              ? "active"
              : ""
              }`}
          >
            <span className={`quix-admin-main-menu`}>
              <Link to={"/admin/dashboard"}>
                <img src={dashboardIcon} />
                Dashboard
              </Link>
            </span>
          </li>
          <li
            className={`quix-admin-menu ${pageTitle === "users" ||
              pathname?.includes("/manage-user-profile")
              ? "active"
              : ""
              }`}
          >
            <span className={`quix-admin-main-menu`}>
              <Link to={"/admin/manage-users"}>
                <img src={userIcon} />
                Users Management
              </Link>
            </span>
          </li>
          <li
            className={`quix-admin-menu ${pageTitle === "manage-image-and-video" ? "active" : ""
              }`}
          >
            <span
              className={`quix-admin-main-menu ${pageTitle === "manage-image-and-video" ? "active" : ""
                }`}
            >
              <Link to={"/admin/manage-image&video"}>
                <img src={mediaIcon} />
                Image & Video Management
              </Link>
            </span>
          </li>
          <li
            className={`quix-admin-menu ${pageTitle === "membership" ? "active" : ""
              }`}
          >
            <span className={`quix-admin-main-menu`}>
              <Link to={"/admin/manage-membership"}>
                <img src={membershipIcon} />
                Membership Management
              </Link>
            </span>
          </li>
          <li
            className={`quix-admin-menu ${pageTitle === "pricing-plans" ? "active" : ""
              }`}
          >
            <span className={`quix-admin-main-menu`}>
              <Link to={"/admin/manage-pricing-plans"}>
                <img src={pricingIcon} />
                Pricing Plan Settings
              </Link>
            </span>
          </li>
          <li
            className={`quix-admin-menu ${pageTitle === "tags" ? "active" : ""
              }`}
          >
            <span className={`quix-admin-main-menu`}>
              <Link to={"/admin/manage-tags"}>
                <img src={pricingIcon} />
                Tags Management
              </Link>
            </span>
          </li>
          <li
            className={`quix-admin-menu ${pageTitle === "extension-settings" ? "active" : ""
              }`}
          >
            <span className={`quix-admin-main-menu`}>
              <Link to={"/admin/extension-static-ui&text"}>
                <img src={extensionIcon} />
                Extension static Ui & Text
              </Link>
            </span>
          </li>
          <li
            className={`quix-admin-menu ${pageTitle === "general-settings" ? "active" : ""
              }`}
          >
            <span className={`quix-admin-main-menu`}>
              <Link to={"/admin/manage-general-settings"}>
                <img src={generalSettingIcon} />
                General Settings
              </Link>
            </span>
          </li>
          {/* <li
            className={`quix-admin-menu ${pageTitle === "membership3" ? "active" : ""
              }`}
          >
            <span className={`quix-admin-main-menu`}>
              <Link to={"/admin/general-settings"}>
                <img src={featuresSettingIcon} />
                Pro Features settings
              </Link>
            </span>
          </li> */}
        </ul>
      </div>
      <div className="side-bar-footer">
        <div className="setting-icon">
          <img src={settingIcon} />
          Settings
        </div>
        <div className="side-user-profile d-flex justify-content-between align-items-center">
          <div className="d-flex side-user-profile-details align-items-center">
            <div className="user-profile">
              <img className="user-profile-img" src={userProfile} />
            </div>
            <div className="user-details">
              <h6>{userName}</h6>
              <p>{userEmail}</p>
            </div>
          </div>
          <div className="logout" onClick={() => handleLogOutUser()}  title="Logout">
            <img src={logOutIcon}></img>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state?.auth?.user_details,
});

export default connect(mapStateToProps, {})(Sidebar);
