import React from 'react';


const InfoCard = ({ iconSrc, title, counting }) => {
    return (
        <div className="col-md-6 col-lg-6 col-xl-3 quix-dashboard-details-card mb-3">
            <div className="dash-report-card card">
                <div className="card-body">
                    <div className="card-badge">
                        <img src={iconSrc} height={"100%"} width={"100%"} alt={title} />
                    </div>
                    <div className="card-details">
                        <h6>{title}</h6>
                        <h5 className="quix-counting-number">{counting}</h5>
                    </div>
                </div>
            </div>
        </div>
    );
};

InfoCard.defaultProps = {
    iconSrc: '',
    title: '',
    counting: 0
}

export default InfoCard;