import React from "react";
import logoImg from "../../../assets/images/home/logo.png";
import slideImg1 from "../../../assets/images/home/slider_1.png";
import slideImg2 from "../../../assets/images/home/slider_2.png";
import slideImg3 from "../../../assets/images/home/slider_3.png";
import slideImg4 from "../../../assets/images/home/slider_4.png";
import { Link } from "react-router-dom";
import { localStoragePrefix } from "../../../utils/config";

const HeaderSection = () => {
  const isLoginIn = localStorage.getItem(localStoragePrefix + "screenGeniusAuthToken");

  return (
    <div className="header_section">
      <div className="container top_container">
        {/* Navbar */}
        <header className="ScreenGenius-header">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <Link className="navbar-brand home-page-logo" to="#">
                <img src={logoImg} height="80" alt="CoolBrand" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav">
                <Link className="nav-item nav-link active" to="/">
                  Home
                </Link>
                <a className="nav-item nav-link" href="#screenshot-tab">
                  Capture
                </a>
                <a className="nav-item nav-link" href="#screen-recording-tab">
                  Recorder
                </a>
                <a className="nav-item nav-link" href="#pricing-plans-tab">
                  Pricing
                </a>
                <a
                  className="nav-item nav-link"
                  href="#frequently-asked-questions"
                >
                  FAQ
                </a>
              </div>
              <div className="navbar-nav ms-auto">
                {isLoginIn !== null ? (
                  <Link className="nav-item nav-link login" to="/dashboard">
                    Dashboard
                  </Link>
                ) : (
                  <a className="nav-item nav-link login" href="/login">
                    Login
                  </a>
                )}

                <Link
                  className="nav-item nav-link get_started"
                  to="https://chrome.google.com/webstore/detail/screengenius-screenshot-s/eclnikmacpcandpbfjkjgnoicmlpkkdj"
                  target="_blank"
                >
                  Get started
                </Link>
              </div>
            </div>
          </nav>
        </header>
        {/* crousel section */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center ScreenGenius-mid">
              <div className="left-content">
                <h2>Capture your screen like a pro!.</h2>
                <p>
                  Say Goodbye to bland Screen Captures! Take screenshots, record
                  screen &amp; effortlessly share with a dash of your awesome
                  personality.
                </p>
                <Link
                  className="btn btn-primary chrome-thisbtn"
                  to="https://chrome.google.com/webstore/detail/screengenius-screenshot-s/eclnikmacpcandpbfjkjgnoicmlpkkdj"
                >
                  Get Chrome Extension
                </Link>
              </div>
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-center ScreenGenius-mid">
              <div
                className="carousel slide"
                id="carouselExampleControls"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  <button
                    className="active"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide-to="0"
                    aria-current="true"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide-to="1"
                    className=""
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide-to="2"
                    className=""
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide-to="3"
                    className=""
                  ></button>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      className="d-block w-100"
                      src={slideImg1}
                      alt="First slide"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={slideImg2}
                      alt="Second slide"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={slideImg3}
                      alt="Third slide"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={slideImg4}
                      alt="Fourth slide"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;
