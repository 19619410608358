import {
  LOADING_ADMIN_MEMBERSHIP,
  GET_ALL_ADMIN_MEMBERSHIP,
  UPDATE_ADMIN_MEMBERSHIP,
  NOT_FOUND_ADMIN_MEMBERSHIP,
} from "../../actions/type";

const INITIAL_STATE = {
  list: [{}],
  membership: [{}],
  is_loading: false,
  not_found: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_ADMIN_MEMBERSHIP:
      return { ...state, is_loading: true };
    case GET_ALL_ADMIN_MEMBERSHIP:
      return {
        ...state,
        list: action.payload,
        is_loading: false,
        not_found: false,
      };
    case NOT_FOUND_ADMIN_MEMBERSHIP:
      return { ...state, list: [], is_loading: false, not_found: true };
    case UPDATE_ADMIN_MEMBERSHIP:
      return {
        ...state,
        membership: action.payload,
        is_loading: false,
        not_found: false,
      };
    default:
      return state;
  }
};
