import React, { useEffect, useState } from "react";
import logoImg from "../../assets/images/home/logo.png";
import thankuEmoji from "../../assets/images/home/thankyouemoji.png";
import extScreen1Img from "../../assets/images/home/extenstion_screen1.png";
import extScreen2Img from "../../assets/images/home/ext_screen_2.png";
import extScreen3Img from "../../assets/images/home/ext_screen_3.jpg";
import extScreen4Img from "../../assets/images/home/ext_screen_4.png";
import extScreen5Img from "../../assets/images/home/ext_screen_5.png";
import extScreen6Img from "../../assets/images/home/ext_screen_6.png";
import extScreen7Img from "../../assets/images/home/ext_screen_7.png";
import extScreen8Img from "../../assets/images/home/ext_screen_8.png";
import extScreen9Img from "../../assets/images/home/ext_screen_9.png";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import TopBar from "../../components/TopBar/TopBar";
import NewFeaturePopup from "./components/NewFeaturePopup";

const ThankYou = () => {

  const [showVizamModal, setShowVizamModal] = useState(true);
  useEffect(() => {
    setShowVizamModal(true);
  }, []);

  return (
    <div className="thankyou-page" id="root">
      <TopBar />
      {showVizamModal && (<NewFeaturePopup isShowModal={showVizamModal} closeModal={setShowVizamModal} />)}
      <div className="header_section">
        <div className="container top_container">
          <header className="ScreenGenius-header">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                  <img className="sitetitle" src={logoImg} height="80" alt="CoolBrand" />
                </Link>
              </div>
            </nav>
          </header>
          <div className="container-fluid">
            <div className="row">
              <div className="star_emoji">
                <span className="thankEmoji">
                  <span className="emoji">
                    <img src={thankuEmoji} />
                    <span className="emoji_content"></span>
                    <h5>For choosing Vizam</h5>
                    <p>
                      Buckle up! Your journey to create stunning masterpieces
                      begins now.
                    </p>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid screen_recording_game">
        <div className="container">
          <div className="row">
            <div className="section_title">
              <h1>To easily access Vizam. Do this first.</h1>
              <p>
                Follow the instructions for a seamless screenshotting
                experience!
              </p>
            </div>
            <div className="col-lg-12 conversations-mid extension_screen">
              <div className="ext_screen_1 exten_screen">
                <span>
                  <h5>01</h5>
                  <p>Access Vizam from Extensions menu.</p>
                </span>
                <span>
                  <img src={extScreen1Img} />
                </span>
              </div>
              <div className="ext_screen_1 exten_screen">
                <span>
                  <h5>02</h5>
                  <p>Pin it to the bar.</p>
                </span>
                <span>
                  <img src={extScreen2Img} />
                </span>
              </div>
              <div className="ext_screen_1 exten_screen">
                <span>
                  <h5>03</h5>
                  <p>
                    Start with selecting Screenshot or Screen recording mode.
                  </p>
                </span>
                <span>
                  <img src={extScreen3Img} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container screen_recording_game">
        <div className="row">
          <div className="section_title">
            <h1>Ready for your first Vizam screenshot?.</h1>
            <p>Here's how you take your first capture.</p>
          </div>
          <div className="col-lg-12 conversations-mid extension_screen">
            <div className="ext_screen_2 exten_screen">
              <span>
                <h5>01</h5>
                <p>
                  To take your first amazing screenshot, simply select the area
                  you want to capture &amp; Vizam will do the rest.
                </p>
              </span>
              <span>
                <img src={extScreen4Img} />
              </span>
            </div>
            <div className="ext_screen_2 exten_screen">
              <span>
                <h5>02</h5>
                <p>
                  Now, edit it or annotate on it, to give your personalized
                  touch.
                </p>
              </span>
              <span>
                <img src={extScreen5Img} />
              </span>
            </div>
            <div className="ext_screen_2 exten_screen">
              <span>
                <h5>03</h5>
                <p>
                  And now, it's ready to share via a shareable link or through
                  an email.
                </p>
              </span>
              <span>
                <img src={extScreen6Img} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid screen_recording_game">
        <div className="container">
          <div className="row">
            <div className="section_title">
              <h1>Screen recording made easy with Vizam.</h1>
              <p>
                And, now do your first screen recording with Vizam like this.
              </p>
            </div>
            <div className="col-lg-12 conversations-mid extension_screen">
              <div className="ext_screen_3 exten_screen">
                <span>
                  <h5>01</h5>
                  <p>
                    Do your first screen recording by selecting your current
                    window, or select all windows, your current tab or your
                    camera.{" "}
                  </p>
                </span>
                <span>
                  <img src={extScreen7Img} />
                </span>
              </div>
              <div className="ext_screen_3 exten_screen">
                <span>
                  <h5>02</h5>
                  <p>
                    Now, add text, effects, music and trim as you edit, with
                    your creative genius hat on!
                  </p>
                </span>
                <span>
                  <img src={extScreen8Img} />
                </span>
              </div>
              <div className="ext_screen_3 exten_screen">
                <span>
                  <h5>03</h5>
                  <p>
                    Now it's ready to share via a sharing link with your
                    friends. You can also upload the screen recordings on to the
                    cloud easily.
                  </p>
                </span>
                <span>
                  <img src={extScreen9Img} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer */}
      <Footer />
    </div>
  );
};

export default ThankYou;
