import React from 'react';

const TelegramLogin = ({ URL, children }) => {
    const handleTelegramLogin = () => {
        const telegramAuthUrl = `https://t.me/share/url?url=${encodeURIComponent(URL)}`;

        const width = 800;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        window.open(telegramAuthUrl, 'Telegram', `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,resizable=yes`);
    }

    return (
        <div onClick={handleTelegramLogin}>
            {children}
        </div>
    );
};

export default TelegramLogin;