import React from 'react';

const WhatsAppLogin = ({ URL, children }) => {
    const handleWhatsAppLogin = () => {
        const authUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
            URL
        )}`;

        const width = 800;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        window.open(authUrl, 'WhatsApp', `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,resizable=yes`);
    }

    return (
        <div onClick={handleWhatsAppLogin}>
            {children}
        </div>
    );
};

export default WhatsAppLogin;