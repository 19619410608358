import {
  LOADING_ADMIN_PRICING_PLAN,
  GET_ALL_ADMIN_PRICING_PLAN,
  UPDATE_ADMIN_PRICING_PLAN,
  ADMIN_ADD_PRICING_PLAN,
  NOT_FOUND_ADMIN_PRICING_PLAN
} from "../../actions/type";

const INITIAL_STATE = {
  list: [{}],
  updated_pricing_plan: [{}],
  response: [],
  is_loading: false,
  notFound: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_ADMIN_PRICING_PLAN:
      return { ...state, is_loading: true, notFound: false };
    case GET_ALL_ADMIN_PRICING_PLAN:
      return { ...state, list: action.payload, is_loading: false, notFound: false };
    case ADMIN_ADD_PRICING_PLAN:
      return { ...state, response: action.payload, is_loading: false, notFound: false };
    case NOT_FOUND_ADMIN_PRICING_PLAN:
      return { ...state, is_loading: false, list: [], notFound: true };
    case UPDATE_ADMIN_PRICING_PLAN:
      return {
        ...state,
        updated_pricing_plan: action.payload,
        is_loading: false,
        notFound: false
      };
    default:
      return state;
  }
};
