import { toast } from 'react-toastify';
import successIcon from '../../assets/images/success-message-icon.png';

// success message
export const successMessageModal = (title, message) => {
    const toastId = toast(
        <div className='message-modal d-flex'>
            <img src={successIcon} alt="success icon" />
            <div className='message-title'>
                <h5>{title}</h5>
                <p>{message}</p>
            </div>
        </div>,
        {
            closeOnClick: false,
            hideProgressBar: true,
            autoClose: 2000,
            style: { background: '#32BB71', border: '1px solid #268759' },
        }
    );
    return toastId;
};

// error message
export const errorMessageModal = (title, message) => {
    const toastId = toast(
        <div className='message-modal d-flex'>
            <img src={successIcon} alt="error icon" />
            <div className='message-title'>
                <h5>{title}</h5>
                <p>{message}</p>
            </div>
        </div>,
        {
            closeOnClick: false,
            hideProgressBar: true,
            autoClose: 2000,
            style: { background: '#D04040', border: '1px solid #B71212' },
        }
    );
    return toastId;
};